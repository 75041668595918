.container-trace-alphabet {
  width: 100%;
  height: 60vh;
  display: flex;
}
.letter-button{
  background-color: #194792;
  border-radius: 50px;
}
.prev-letter-but,
.next-letter-but {
  width: 35%;
  height: 30%;
  margin-left: 15px;
  margin-top: 8rem;
  padding: 1px 12px;
  font-size: 43px;
  border-radius: 50px;
  cursor: pointer;
}
.modal-backdrop.show {
  filter: blur(5px); 
  opacity: 0.7;
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #99aae2;
  --bs-backdrop-opacity: 0.7;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.pencil-eraser{
  height: 20rem!important;
  width: 6rem;
  margin-left: -22rem;
  background-image: url(../../../public/image/eraser.png);
}
.right-section1 {
    margin-left: 0%;
    margin-bottom: 0%;
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
  }
  h5{
    font-size: 30px;
    text-align: center;
    margin-top: -28rem;
    margin-left: 25rem;
    font-family: cursive;
    background-color: #FFCB23;
  }
  .trace-all {
   width: 22.5vw;
   height: 61.2vh;
   margin-left: 48px;
   margin-top: 0.8rem;
   display: grid;
   grid-template-columns: 30% 70% ;
   padding: 10px;
  }
  .gif {
    text-align: center;
    padding: 20px 0;
  }
  .trace-gif{
    width: 30vw;
    height: 51.7vh;
    margin-top: -1.9rem;
  }
  .gif-img {
   text-align: center;
   padding: 20px 0;
   margin-top: 21rem;
   margin-left: 46rem;
  }
  .trace-img{
    height: auto;
    width: auto;
    margin-top: -400px;
    margin-left: -150px;
  }
  .rec-text{
    height: 2rem;
    width: 5rem;
    border-radius: 50px;
    padding-top: 5px;
    background-color: #194792;
    color: white;
    font-family: cursive;
  }
  .clear-but{
    height: 2rem;
    width: 5rem;
    margin-left: 2rem; 
    padding-top: 5px;
    background-image: url(../../../public/image/eraser.png);
  }
  .footer-part{
    display: flex;
    margin-Top :-4rem;
  }
  .but-part{
    display: flex;
    margin-Left :30px;
    margin-Top :0.5rem;
  }
  .canva{
    width: 800px;
    height: 400px;
    margin-left: 29rem;
    margin-top: 6.5rem;
    background-Color : white ;
  }
  .extract-text{
    margin-left: 70rem;
    margin-top: -3rem;
  }
  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 800px;
    height: 45rem;
    pointer-events: auto;
}
.cong-gif {
  width: 700px;
  height: 500px;
  margin-top: 7rem;
  margin-left: -20rem;
}
.sry-gif{
  width: 700px;
  height: 500px;
  margin-top: 7rem;
  margin-left: -20rem;
}
@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.slide-in-from-left {
  animation: slideInFromLeft 1s ease-out;
}

.slide-in-left-modal {
  animation: slideInLeftModal 1s ease-in-out;
}

@keyframes slideInLeftModal {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}


  @media (max-width: 1024px) and (max-height: 768px)   {
  .right-section1 {
    margin-left: 0%;
    margin-bottom: 0%;
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    overflow-x: hidden;
  }
  h5{
    font-size: 34px;
    text-align: center;
    height: 3rem;
    width: 12rem;
    border-radius: 10px;
    margin-top: -35rem;
    margin-left: 32rem;
    font-family: cursive;
    background-color: #FFCB23;
  }
  .trace-all {
   width: 22.5vw;
   height: 65.2vh;
   margin-left: 48px;
   margin-top: -0.2rem;
   display: grid;
   grid-template-columns: 30% 70% ;
   grid-gap: 0px;
   padding: 10px;
  }
  .trace-gif{
    width: 23rem;
    height: 21rem;
    margin-top: -1rem;
    margin-left: -1.5rem;
  }
  .trace-img{
    height: 25rem !important;
    width: auto !important;
    margin-top: -20rem;
    margin-left: -20rem;
  }
  .rec-text{
    height: 4rem;
    width: 11rem;
    font-size: 30px;
    border-radius: 30px;
    margin-top: 9rem;
    background-color: #194792;
    color: white;
    padding: 0px;
    font-family: cursive;
    margin-left: 25rem;
  }
  .pencil-eraser{
    width: 6rem;
    margin-left: -20rem;
    margin-top: -3rem;
    background-image: url(../../../public/image/eraser.png);
  }
  .footer-part{
    display: flex;
    flex-direction: row;
    height: 12rem;
    margin-left: 2rem;
    margin-Top :-7.5rem;
  }

  .but-part{
    display: flex;
    margin-Left :30px;
    margin-Top :0.5rem;
  }
  .canva{
    width: 600px;
    height: 500px;
    margin-left: 25rem;
    margin-top: 5.5rem;
    background-Color : white ;
    border:rgba(255, 203, 35, 1) 5px solid;
  }
  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 800px;
    height: 45rem;
  background-color: transparent !important;
  border: none !important;
    pointer-events: auto;

}
  .cong-gif 
  .sry-gif{
    width: 700px;
    height: 400px;
    margin-top: -1rem;
    margin-left: -25rem;
  }
  .prev-letter-but{
    width:  5rem;
    height: 22%;
    margin-left: -11.5rem;
    margin-top: -27rem;
    padding: 1px 12px;
    font-size: 25px;
    border-radius: 10px;
    cursor: pointer;
    border: #194792 2px solid;
    color:  #194792;

}
.next-letter-but{
  width: 5rem;
  height: 22%;
  margin-top: -27rem;
  padding: 1px 12px;
  font-size: 25px;
  border-radius: 10px;
  cursor: pointer;
  background-color:#194792;
}
  }
  @media (min-width:1025px) and (max-width:1280px) and (max-height:720px) {
    .right-section1 {
      margin-left: 0%;
      margin-bottom: 0%;
      width: 100vw;
      height: 100vh;
      background-repeat: no-repeat;
      background-size: cover;
      overflow-x: hidden;
    }
    h5{
      font-size: 35px;
      height: 3rem;
      width: 13rem;
      border-radius: 10px;
      text-align: center;
      margin-top: -33rem;
      margin-left: 40rem;
      font-family: cursive;
    }
    .trace-all {
     width: 22.5vw;
     height: 65.2vh;
     margin-left: 48px;
     margin-top: -0.2rem;
     display: grid;
     grid-template-columns: 30% 70% ;
     grid-gap: 0px;
     padding: 10px;
    }
    .trace-gif{
      width: 20rem;
      height: 20rem;
      margin-top: -1.5rem;
      margin-left: 4rem;
    }
    .trace-img{
      height: auto;
      width: 23rem !important;
      margin-top: -25rem;
      margin-left: -15rem;
    }
    .modal-content {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 800px;
      height: 45rem;
    background-color: transparent !important;
    border: none !important;
      pointer-events: auto;
  
  }
    .rec-text{
      height: 4rem;
      width: 13rem;
      border-radius: 30px;
      padding: 5px;
      margin-left: 31rem;
      margin-top: 10rem;
      background-color: #194792;
      color: white;
      font-size: 28px;
    }
    .pencil-eraser{
      width: 6rem;
      margin-left: -21rem;
      margin-top: -2.5rem ;
      background-image: url(../../../public/image/eraser.png);
    }
    .footer-part{
      display: flex;
      margin-Left :6rem;
      margin-Top :-9rem;
    }
    .input-part{
      margin-top: 1rem;
    }
    .but-part{
      display: flex;
      margin-Left :30px;
      margin-Top :0.5rem;
    }
    .canva{
      width: 750px;
      height: 470px;
      margin-left: 28rem;
      margin-top: 5rem;
      background-Color : white ;
      border:rgba(255, 203, 35, 1) 5px solid;
    }
    .modal-content {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 700px;
      height: 402px;
      color: var(--bs-modal-color);
      pointer-events: auto;
      background-clip: padding-box;
      border-radius: var(--bs-modal-border-radius);
  }
  .prev-letter-but{
    width:  5rem;
    height: 14%;
    margin-left: -11rem;
    margin-top: -24rem;
    padding: 1px 12px;
    font-size: 25px;
    border-radius: 10px;
    font-weight: bold;
    border: #194792 2px solid;
    color:  #194792;
}
.next-letter-but{
  width: 5rem;
  height: 14%;
  margin-top: -24rem;
  padding: 1px 12px;
  font-size: 25px;
  border-radius: 10px;
  cursor: pointer;
  background-color:#194792;
}
.sry-gif 
{
  margin-left: -27rem;
}
.cong-gif{
  margin-left: -27rem;
}
  }

  @media(min-width:1366px) and (min-height:768px) {
    .right-section1 {
      margin-left: 0%;
      margin-bottom: 0%;
      width: 100vw;
      height: 100vh;
      background-repeat: no-repeat;
      background-size: cover;
      overflow-x: hidden;
    }
    h5{
      font-size: 38px;
      text-align: center;
      margin-top: -36rem;
      border-radius: 10px;
      width: 15rem;
      height: 3.5rem;
      margin-left: 41rem;
    
    }
    .trace-all {
     width: 22.5vw;
     height: 61.2vh;
     margin-left: 48px;
     margin-top: 0.8rem;
     display: grid;
     grid-template-columns: 30% 70% ;
     padding: 10px;
    }
    .trace-gif{
      width: 30vw;
      height: 43vh;
      margin-top: -1rem;
      margin-left: 2.8rem;
    }
    .trace-img{
      height: 33rem;
      width: auto;
      margin-top: -370px;
      margin-left: -160px;
    }
    .rec-text{
      height: 3.8rem;
      width: 15rem;
      border-radius: 50px;
      padding: 3px;
      margin-top: 9.5rem;
      margin-left: 31rem;
      background-color: #194792;
      color: white;
      font-size: 34px;
    }
    .pencil-eraser{
      width: -1rem;
     margin-left: -20rem;
      margin-top: -2.5rem;
      background-image: url(../../../public/image/eraser.png);
    }
    .prev-letter-but{
      width: 6rem;
      height: 16%;
      margin-left: -13rem;
      margin-top: -26rem;
      padding: 1px 12px;
      font-size: 30px;
      border-radius: 10px;
      font-weight: bold;
      border: #194792 2px solid;
      color:  #194792;
  }
  .next-letter-but{
    width: 6rem;
    height: 16%;
    margin-top: -26rem;
    padding: 0px 12px;
    font-size: 30px;
    border-radius: 10px;
    cursor: pointer;
    background-color: #194792;
  }
    .footer-part{
      display: flex;
      margin-Left :7rem;
      margin-Top :-7rem;
    }
    .but-part{
      display: flex;
      margin-Left :30px;
      margin-Top :0.5rem;
    }
    .canva{
      width: 750px;
      height: 500px;
      margin-left: 32rem;
      margin-top: 6.5rem;
      background-Color : white ;
      border:rgba(255, 203, 35, 1) 5px solid;
    }
    .modal-content {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 800px;
      height: 402px;
      color: var(--bs-modal-color);
      pointer-events: auto;
      background-clip: padding-box;
      border-radius: var(--bs-modal-border-radius);
      background-color: transparent !important;
      border: none !important;
      pointer-events: auto;
  } 
  .sry-gif {
    width: 700px;
    height: 500px;
    margin-top: 12rem;
    margin-left: -30rem;
}
.cong-gif{
  width: 700px;
  height: 500px;
  margin-top: 12rem;
  margin-left: -30rem;
}
  }
  @media (min-width:1367px) and (max-width:1920px) and (max-height:1080px) {
    .right-section1 {
      margin-left: 0%;
      margin-bottom: 0%;
      width: 100vw;
      height: 100vh;
      background-repeat: no-repeat;
      background-size: cover;
      overflow-x: hidden;
    }
    h5{
      font-size: 45px;
      height: 4rem;
      width: 18rem;
      text-align: center;
      margin-top: -39rem;
      margin-left: 57rem;
      font-family: cursive;
    }
    .trace-all {
     width: 22.5vw;
     height: 60.2vh;
     margin-left: 120px;
     margin-top: 0.8rem;
     display: grid;
     grid-template-columns: 30% 70% ;
     padding: 10px;
    }
    .trace-gif{
      width: 27vw;
      height: 40.5vh;
      margin-left: 10rem;
      margin-top: 1rem;
    }
    .trace-img{
      height:auto;
      width:25rem;
      margin-top: -360px;
      margin-left: -10px;
    }
    .rec-text{
      height: 5rem;
      width: 16rem;
      border-radius: 30px;
      padding-top: 1px;
      background-color: #194792;
      color: white;
      margin-top: 3rem;
      margin-left: 36rem;
      font-family: cursive;
      font-size: 2.5rem;
    }
    .pencil-eraser{
   
      margin-left: -22rem;
      margin-top: -3rem;
      background-image: url(../../../public/image/eraser.png);
    }
    .footer-part{
      display: flex;
      margin-Left :22rem;
      margin-Top :-8rem;
    }
    .but-part{
      display: flex;
      margin-Left :30px;
      margin-Top :0.5rem;
    }
    .canva{
      width: 800px;
      height: 500px;
      margin-left: 50rem;
      margin-top: 10.5rem;
      background-Color : white ;
    }
    .modal-content {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 800px;
      height: 402px;
      color: var(--bs-modal-color);
      pointer-events: auto;
      background-clip: padding-box;
      border-radius: var(--bs-modal-border-radius);
      background-color: transparent !important;
      border: none !important;
      pointer-events: auto;  
  } 
  .sry-gif {
    width: 700px;
    height: 500px;
    margin-top: 12rem;
    margin-left: -47rem;
}
.cong-gif{
  width: 700px;
  height: 500px;
  margin-top: 12rem;
  margin-left: -47rem;
}
.next-letter-but 
{
  width: 7rem;
  height: 23%;
  margin-top: -35rem;
  padding: 0px 12px;
  font-size: 40px;
  border-radius: 10px;
  background-color: #194792;
}
.prev-letter-but{
  width: 7rem;
  height: 23%;
  margin-left: -15rem;
  margin-top: -35rem;
  padding: 0px 12px;
  font-size: 40px;
  border-radius: 10px;
  font-weight: bold;
  border: #194792 2px solid;
  color:  #194792;
}
}
 