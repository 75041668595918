.activity-section{
    background-image: url(../../Asset/bg_7.png);
    text-align: center;
    height: 100vh;
    width: 100%;
    top: 0;
    position: fixed; 
    overflow:scroll;   
    overflow-x: hidden; 
}
.activity-block{
    display: flex;
    flex-wrap:wrap;
    flex-direction: row;
    align-content: center;
    margin-left: -15rem;
}
.activity_img {
    width: auto;
    height: auto;
    margin-left: 3rem;
    margin-top: 2rem;
    border-radius: 16px;
    cursor: url(/public/pointer.png), auto;
    /* box-shadow: 3px 4px 5px black; */
  }
  .activity-container{
    background-image: url(../../Asset/bg_7.png);
    text-align: center;
    height: 100vh;
    width: 100%;
    top: 0;
    overflow:scroll; 
}
.activity-main-card{ 
    align-content: center;
    display: flex;
    flex-wrap:wrap;
    flex-direction: row;  
    margin-top: 1rem;
    margin-left: 15rem;
}
@media(max-width: 1024px) and (max-height: 768px)   {
.activity-section{
    background-image: url(../../Asset/bg_7.png);
    text-align: center;
    height: 100vh;
    width: 100%;
    top: 0;
    position: fixed; 
    overflow:scroll;   
    overflow-x: hidden; 
}
.activity_img {
    width: auto;
    height: auto;
    margin-left: 2rem;
    margin-top: 2rem;
    cursor: url(/public/pointer.png), auto;
}
.activity-main-card{ 
    align-content: center;
    display: flex;
    flex-wrap:wrap;
    flex-direction: row;  
    margin-top: -1rem;
    margin-left: 15.2rem;
}
}
@media (min-width:1025px) and (max-width:1280px) and (max-height:720px) {
.activity-section{
    background-image: url(../../Asset/bg_7.png);
    text-align: center;
    height: 100vh;
    width: 100%;
    top: 0;
    position: fixed; 
    overflow: hidden;
}
.activity-main-card{ 
    align-content: center;
    display: flex;
    flex-wrap:wrap;
    flex-direction: row;  
    margin-top: 0.3rem;
    margin-left: 16rem;
}
.activity_img {
    width: 200px;
    height: 290px;
    margin-left: 2.5rem;
    margin-top: 1.2rem;
    cursor: url(/public/pointer.png), auto;
}
}
@media(min-width:1366px) and (min-height:768px) {
.activity-section{
    background-image: url(../../Asset/bg_7.png);
    text-align: center;
    height: 100vh;
    width: 100%;
    top: 0;
    position: fixed; 
    overflow: hidden;
}
.activity-main-card{ 
    align-content: center;
    display: flex;
    flex-wrap:wrap;
    flex-direction: row;  
    margin-top: -0.3rem;
    margin-left: 15.7rem;
}
.activity_img {
    width: auto;
    height: auto;
    margin-left: 3rem;
    margin-top: 1rem;
    cursor: url(/public/pointer.png), auto;
}
}
@media(min-width:1367px) and (max-width:1920px) and (max-height:1080px) {
.activity-section{
    background-image: url(../../Asset/bg_7.png);
    text-align: center;
    height: 100vh;
    width: 100%;
    top: 0;
    position: fixed; 
    overflow:scroll;   
    overflow-x: hidden; 
    overflow-y: hidden;
}
.activity-main-card{ 
    align-content: center;
    display: flex;
    flex-wrap:wrap;
    flex-direction: row;  
    margin-top: 2rem;
    margin-left: 14rem;
}
.activity_img {
    width: 230px;
    height: 350px;
    margin-left: 5rem;
    margin-top: 3rem;
    cursor: url(/public/pointer.png), auto;
}
}
