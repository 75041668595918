.tracing-container{
    background-image: url(../../Asset/alphabet_number_bg.png);
    text-align: center;
    height: 100vh;
    width: 100%;
    top: 0;
    overflow:scroll; 
    overflow-x: hidden;
}
.tracing-block-card{ 
    align-content: center;
    display: flex;
    flex-wrap:wrap;
    flex-direction: row;
     position: relative;
    transform-style: preserve-3d;
    transition: transform 0.5s;
    margin-left: 0.5rem;
    margin-top: -5rem;    
}

.tracing-card{ 
    position: relative;
    transform-style: preserve-3d;
    cursor:pointer;
    transition: transform 0.5s;
   }
   .card.flipped {
       transform: rotateY(180deg);
   }
  .tracing-card-front{
    position: relative;
    width: 13rem;
    height: 13rem;
    margin-left: 3rem;
    margin-top: 2rem;
    margin-bottom: 7rem;
  }
.tracing-card-back{
    position:absolute;
    backface-visibility:hidden;
    transform: rotateY(180deg);
}

  @media(max-width: 1024px) and (max-height: 768px)   {
    .tracing-card-front{
        position: relative;
        width: 13rem;
        height: 13rem;
        margin-left: 2rem;
        margin-top: 2rem;
        margin-bottom: 7rem;
      }
    .tracing-card-back{
        position:absolute;
        backface-visibility:hidden;
        transform: rotateY(180deg);
    }
    .tracing-block-card{ 
      margin-top: -2.5rem;    
  }
  }
  @media (min-width:1025px) and (max-width:1280px) and (max-height:720px) {
    .tracing-card-front{
        position: relative;
        width: 13rem;
        height: 13rem;
        margin-left: 2rem;
        margin-top: 2rem;
        margin-bottom: 7rem;
      }
    .tracing-card-back{
        position:absolute;
        backface-visibility:hidden;
        transform: rotateY(180deg);
    }
    .tracing-block-card{ 
      margin-top: -2rem;  
      margin-left: 0.9rem;  
  }
  }
  @media (min-width:1366px) and (min-height:768px) {
    .tracing-card-front{
        position: relative;
        width: 13rem;
        height: 13rem;
        margin-left: 3rem;
        margin-top: 2rem;
        margin-bottom: 7rem;
      }
    .tracing-card-back{
        position:absolute;
        backface-visibility:hidden;
        transform: rotateY(180deg);
    }
    .tracing-block-card{ 
      margin-top: -2rem;    
  }
  }
  @media (min-width:1367px) and (max-width:1920px) and (max-height:1080px) {
    /* .tracing-container{
       overflow-y: hidden;
    } */

    .tracing-card-front{
      position: relative;
      width: 13rem;
      height: 13rem;
      margin-left: 5rem;
      margin-top: 1rem;
      margin-bottom: 7rem;
    }
   .tracing-card-back{
      position:absolute;
      backface-visibility:hidden;
      transform: rotateY(180deg);
  }
   .tracing-block-card{ 
    margin-top: 2rem;  
    margin-left: 3rem;  
  }
  }