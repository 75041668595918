/* Addition component CSS */
.addition-main-container{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../Asset/add_sub_bg.png'); 
  background-size: cover;
  z-index: -1;
}
.add-para{
  height: 3rem;
  width: 20rem;
  border-radius: 10px;
  text-align: center;
  background-color: #FFC50C;
  font-size: 30px;
  padding: -2px;
  font-family: 'Comic Sans MS', cursive;
  margin-top: 0.5rem;
  margin-left: 33rem;
}
 .add-set {
   text-align: center;
   margin-top: -3rem;
   margin-left: 17rem;
  }
  .add-equation {
    width: 950px;
    height: 150px;
    font-size: 60px;
    margin-top: -90px;
    margin-bottom: 100px;
    border: 5px solid #FFC50C;
  }
  .add-equation-box {
    width: 950px;
    height: 150px;
    margin-left: 5rem;
    display: inline-flex; 
    padding: 5px;
  }
  .add-equation-num1 {
    width: 170px;
    height: 170px;
    margin-left: -2rem;
    margin-right: 6rem;
    margin-top: 0.2rem;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  .add-equation-num2 {
    width: 170px;
    height: 170px;
    margin-left: 3rem;
    margin-right: 6rem;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  .add-answer {
    width: 130px;
    height: 130px;
    margin-left: 0.5rem;
    margin-top: -0.6rem;
    background-color: skyblue;
    border: 3px solid whitesmoke;
    border-radius: 100px;
   }
   .add-answer-number{
    margin-left: -0.5rem;
    font-family: 'Fraunces', serif;
  }
  .add-equation-placeholder {
    width: 130px;
    height: 130px;
    font-weight: bold;
    font-size: 60px;
    margin-left: 0.5rem;
    margin-right: -2rem;
    margin-top: -0.6rem;
    background-color: skyblue;
    border: 3px solid whitesmoke;
    border-radius: 100px;
  }
  .add-equation-image{
    height: 45px;
    width: 45px;
    margin-top: 0.5rem;
    margin-left: -1rem;
    margin-right: 1.5rem;
  }
 .correct-message {
    color: green;
 }
 .wrong-message {
    color: red;
 }
 .add-options-row {
    display: flex;
    margin-left: -2rem;
    margin-top: -6.5rem;
    font-size: 65px;
  }
  .add-options-row > div {
    width: 150px;
    height: 150px;
    background-image: url(../../Asset/add_sub_option_bg.png);
    border-radius: 100px;
    cursor: pointer;
    margin-left: 7.5rem;
    z-index: 1;
  }
  .add-option-number{
    margin-top: 2.5rem;
    margin-left: 0.5rem;
    color: black;
    font-family: 'Fraunces', serif;
    z-index: 1;
   }
   .add-ans-count{
    position: fixed;
    margin-top: -4.5rem;
    right: 5rem;
    height: 3rem;
    width: 12rem;
    background-color: #2d71b6;
    color: black;
    border-radius: 10px;
  }
  .add-set-btn{
    height: 2.5rem;
    width: 2.5rem;
    margin-top: 30rem;
    margin-left: 10rem;
    padding: 3px;
    cursor: pointer;
  }
  .add-home-btn{
    height: 2.5rem;
    width: 2.5rem;
    margin-top: -4.2rem;
    margin-left: 6rem;
    padding: 3px;
    cursor: pointer;
  }
  .add-pause-modal{
    width: 320px;
    height: 270px;
    background-color: aliceblue;
    overflow: hidden;
  }
  .add-pause-btn{
    height: 2.5rem;
    width: 2.5rem;
    margin-top: 5rem;
    margin-left: 5rem;
    padding: 2px;
    cursor: pointer;
  }
  .add-pause-title{
    color: #c99d0c;
    margin-top: -2rem;
    margin-left: 4rem;
  }
  .add-pause-para{
    text-align: center;
    margin-top: 1.5rem;
    font-size: 20px;
    font-weight: bold;
    font-family: 'Comic Sans MS', cursive;
    color: #B22237;
  }
  .add-play-btn{
    font-size: 1.5rem;
    height: 3rem;
    width: 3rem;
    border-radius: 50px;
    padding: 2px 5px ;
    margin-right: 6rem;
  }
  .add-setting-modal{
    width: 300px;
    height: 270px;
    margin-top: 25rem;
    margin-left: 35rem;
    background-color: aliceblue;
    overflow: hidden;
  }
  .add-set-title{
    margin-top: -2rem;
    margin-left: 5rem;
  }
  .add-volume-icon{
    size: 2rem;
    display: flex;
    margin-top: 1.5rem;
    margin-left: 2rem;
  }
  .add-switch{
    margin-left: 1rem;
  }
  .add-back-btn{
    background-color: #194792;
    height: 2.5rem;
    width: 5rem;
    margin-top: 2rem;
    padding: 3px;
    font-size: 20px;
  }
  .add-count-value {
    font-size: 27px;
    margin-top: 2px;
    margin-left: 12px;
    text-align: center;
    font-family: 'Comic Sans MS', cursive;
  }
  .add-coin-image {
    width: 35px; 
    height: auto;
    margin-left: 50px;
    margin-top: -5px;
  }
  .add-congrats-modal1{
    animation: slideInFromLeft 1s ease-out;
  }
  .add-complete-set1 {
    height: 618px;
    width: 1000px;
    margin-top: -11rem;
    margin-left: -38rem;
  }
  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 300px !important;
    height: 250px !important;
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-clip: padding-box;
    border-radius: var(--bs-modal-border-radius);
  }
/* media query */
@media (max-width: 1024px) and (max-height: 768px) {
  .add-para{
    font-size: 30px;
    margin-top: 0.2rem;
    margin-left: 18rem;
    padding: -3px;
  }
  .add-set {
    margin-top: 65px;
    margin-left: 0.6rem;
  }
  .add-equation {
    width: 830px;
    height: 150px;
    margin-top: -90px;
    margin-bottom: 95px;
  }
  .add-equation-box {
    margin-left: 7rem;
    display: inline-flex; 
    padding: 5px;
  }
  .add-equation-num1 {
    width: 150px;
    height: 150px;
    margin-left: -4.5rem;
    margin-right: 6.5rem;
  }
  .add-equation-num2 {
    width: 150px;
    height: 150px;
    margin-left: 1.5rem;
    margin-right: 6rem;
  }
  .add-equation-placeholder {
    width: 130px;
    height: 130px;
    margin-left: 0.5rem;
    margin-right: -2rem;
    margin-top: -0.6rem;
  }
  .add-answer {
    width: 130px;
    height: 130px;
    margin-left: 0.5rem;
    margin-top: -0.6rem;
   }
  .add-options-row {
    margin-left: -1.5rem;
    margin-top: -5.5rem;
    font-size: 65px;
  }
  .add-pause-modal{
    margin-top: 17rem;
    margin-left: 22rem;
  }
  .add-pause-btn{
    margin-top: -7rem;
    margin-left: 5rem;
  }
  .add-setting-modal{
    margin-top: 25rem;
    margin-left: 39rem;
  }
  .add-set-btn{
    margin-top: 34rem;
    margin-left: 10rem;
  }
  .add-home-btn{
    margin-top: -4.2rem;
    margin-left: 6rem;
  }
  .add-ans-count{
    height: 2.5rem;
    margin-top: -3.7rem;
    right: 3rem;
  }
  .add-count-value {
    font-size: 27px;
    margin-top: -2px;
    margin-left: 12px;
  }
  .add-congrats-modal1{
    margin-top: -1rem;
    margin-left: -16rem;
    overflow: hidden;
  }
  .add-complete-set1 {
    height: 500px;
    width: 850px;
    margin-top: -5rem;
    margin-left: -6rem;
  }
}

@media (min-width:1025px) and (max-width:1280px) and (max-height:720px){
  .add-para{
    font-size: 30px;
    margin-top: 0.2rem;
    margin-left: 28rem;
    padding: -4px;
  }
  .add-set {
    margin-top: 55px;
    margin-left: 10rem;
  }
  .add-equation {
    width: 950px;
    height: 140px;
    margin-bottom: 95px;
  }
  .add-options-row {
    margin-left: -1rem;
    margin-top: -6rem;
    margin-top: -95px;
    margin-bottom: 80px;
  }
  .add-equation-placeholder {
    width: 120px;
    height: 120px;
    margin-left: 2.5rem;
    margin-right: -2rem;
    margin-top: -0.5rem;
  }
  .add-answer {
   width: 120px;
   height: 120px;
   margin-left: 2.5rem;
   margin-top: -0.5rem;
  }
  .add-pause-modal{
    margin-top: 17rem;
    margin-left: 30rem;
  }
  .add-pause-btn{
    margin-top: -7rem;
    margin-left: 5rem;
  }
  .add-setting-modal{
    margin-top: 22rem;
    margin-left: 55rem;
  }
  .add-set-btn{
    margin-top: 30rem;
    margin-left: 10rem;
  }
  .add-home-btn{
    margin-top: -4.2rem;
    margin-left: 6rem;
  }
  .add-ans-count{
    height: 2.5rem;
    margin-top: -3.7rem;
    right: 3rem;
  }
  .add-count-value {
    font-size: 27px;
    margin-top: -2px;
    margin-left: 12px;
  }
  .add-congrats-modal1 {
    margin-top: -1rem;
    margin-left: 0.2rem;
  }
  .add-complete-set1 {
    height: 560px;
    width: 910px;
    margin-top: -7rem;
    margin-left: -34rem;
  }
}

@media(min-width:1366px) and (min-height:768px){
  .add-para{
    margin-top: 0.7rem;
    margin-left: 30rem;
  }
  .add-set {
    margin-top: 60px;
    margin-left: 10.5rem;
  }
  .add-equation {
    margin-bottom: 95px;
  }
  .add-options-row {
    margin-left: -1.5rem;
    margin-top: -5.5rem;
  }
  .add-equation-placeholder {
    margin-left: 2.5rem;
    margin-right: -2rem;
    margin-top: -0.5rem;
  }
  .add-answer {
   margin-left: 2.5rem;
   margin-top: -0.5rem;
  }
  .add-pause-modal{
    margin-top: 17rem;
    margin-left: 30rem;
  }
  .add-pause-btn{
    margin-top: -4rem;
    margin-left: 5rem;
  }
  .add-setting-modal{
    margin-top: 25rem;
    margin-left: 60rem;
  }
  .add-set-btn{
    margin-top: 33rem;
    margin-left: 8rem;
  }
  .add-home-btn{
    margin-top: -4.2rem;
    margin-left: 4rem;
  }
  .add-ans-count{
    height: 2.7rem;
    margin-top: -3.7rem;
    right: 3rem;
  }
  .add-count-value {
    font-size: 27px;
    margin-top: -1px;
    margin-left: 12px;
  }
  .add-congrats-modal1 {
    margin-top: -1rem;
    margin-left: -22.5rem;
    overflow: hidden;
  }
  .add-complete-set1 {
    height: 560px;
    width: 910px;
    margin-top: -8rem;
    margin-left: -7rem;
  }
}

@media (min-width:1367px) and (max-width:1920px) and (max-height:1080px) and (display-mode:fullscreen){
    .add-para{
      height: 5rem;
      width: 30rem;
      padding: 4px;
      font-size: 40px;
      margin-top: 1rem;
      margin-left: 43rem;
    }
    .add-set {
      margin-top: 90px;
      margin-left: 23rem;
    }
    .add-equation {
      width: 1150px;
      height: 180px;
      font-size: 60px;
      margin-top: -70px;
      margin-bottom: 100px;
    }
    .add-equation-box {
      width: 950px;
      height: 150px;
      margin-left: 5rem;
      display: inline-flex; 
      padding: 12px;
    }
    .add-equation-num1 {
      width: 180px;
      height: 180px;
      margin-left: -4rem;
      margin-right: 9rem;
    }
    .add-equation-num2 {
      width: 180px;
      height: 180px;
      margin-left: 4rem;
      margin-right: 9rem;
    }
    .add-equation-image{
      height: 55px;
      width: 55px;
      margin-top: 0.5rem;
      margin-left: -1rem;
      margin-right: 1.5rem;
    }
    .add-options-row {
      margin-left: 4rem;
      margin-top: -4rem;
    }
    .add-options-row > div {
      width: 150px;
      height: 150px;
      margin-left: 9.5rem;
      z-index: 1;
    }
    .add-equation-placeholder {
      width: 140px;
      height: 140px;
      margin-left: 3.5rem;
      margin-right: -2rem;
      margin-top: 0.1rem;
    }
    .add-answer {
     width: 140px;
     height: 140px;
     margin-left: 3.5rem;
     margin-top: 0.1rem;
    }
    .add-pause-modal{
    width: 350px;
    height: 300px;
    margin-top: 25rem;
    margin-left: 45rem;
    }
    .add-pause-btn{
      margin-top: -1rem;
      margin-left: 7rem;
    }
    .add-pause-title{
      font-size: 25px;
      margin-left: 5rem;
    }
    .add-pause-para{
      font-size: 25px;
      margin-left: 1.4rem;
    }
    .add-play-btn{
      padding: 2px 5px ;
      margin-right: 5.5rem;
    }
    .add-setting-modal{
      margin-top: 40rem;
      margin-left: 91rem;
    }
    .add-set-btn{
      margin-top: 47rem;
      margin-left: 8rem;
    }
    .add-home-btn{
      margin-top: -4.2rem;
      margin-left: 4rem;
    }
    .add-ans-count{
      height: 2.7rem;
      margin-top: -5rem;
      right: 7rem;
    }
    .add-count-value {
      font-size: 27px;
      margin-top: -1px;
      margin-left: 12px;
    }
    .add-congrats-modal1 {
      margin-top: -1rem;
      margin-left: 0.2rem;
      overflow: hidden;
    }
    .add-complete-set1 {
      height: 618px;
      width: 1000px;
      margin-top: -12rem;
      margin-left: -50rem;
    }
}

