.loader{
  width: 100px;

  animation: spinner 1.0s linear infinite;
}

 
 @keyframes spinner {
   0% {
     transform: rotate(0deg);
   }
   25% {
     transform: rotate(45deg);
   }
   50% {
       transform: rotate(90deg);
     }
 }
 

 @media (min-width: 1000px) and (max-width: 1024px){
  
  .loader{    
  margin-top: 40%;
  margin-left:30%;

  }
  
 }

 @media (min-width: 1025px) and (max-width: 1280px){
  
  .loader{    
  margin-top: 30%;
  margin-left:35%;

  }
  
 }
 @media (min-width: 1280px) and (max-width: 1366px){
  
  .loader{    
  margin-top: 30%;
  margin-left:35%;

  }
  
 }
 @media (min-width: 1366px) and (max-width: 1920px){
  
  .loader{    
  margin-top: 30%;
  margin-left:35%;

  }   
 }