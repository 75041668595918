.navbar {
  display: flex;
  align-items: center;
  background-color: #194792;
  color: white;
  margin: 4.2px;
  margin-top: 0 !important;
  border-radius: 12px;
  backdrop-filter: blur(25px);
  border: 2px solid #ffcb23;
  box-shadow: inset 0 0 2px 2px #fddf2e;
  height: 82px;
  position: sticky;
  position: -webkit-sticky;
}

.navbar__title>img {
  width: 72px;
  height: 58px;
  margin-bottom: 45px;
  align-items: center;
  justify-content: center;
  margin-left: 36px;
  border-radius: 55%;
}

.navbar__item {
  padding: 10px 65px;
  cursor: pointer;
  vertical-align: middle;
}

.container-log {
  background-color: white;
  position: relative;
  margin-bottom: 60%;
  font-size: smaller;
}

.logoutprofile>img {
  height: 55px;
  width: 57px;
  display: inline-block;
  border-radius: 50%;
  margin-top: -45px;
  margin-right: -41px;
}

.logoutmouseover {
  border: solid 5px;
}

.logoutpop {
  font-family: sans-serif;
  font-size: 10px;
  text-align: center;
}

.x-close {
  width: 18%;
  height: 15%;
  margin-left: 88%;
  margin-top: -30%;
  border-radius: 50%;
  background-color: #194792;
  color: white;
  align-items: center;
}

.school_info {
  margin-top: -12%;
  text-align: center;
  font-weight: bold;
  height: 79px;
}

.school_info img {
  width: 130px;
}

.logout-btn {
  text-align: center;
  background-color: #fcb116;
  box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
  margin-top: 75%;
  margin-left: 0rem;
  border: none;
  border-radius: 12px;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  color: white;
  padding: 5% 30%;
  border-radius: 5%;
  align-items: center;
  overflow: none;
}
.backbutton {
  margin-left: 25px;
  margin-right: -25px;
  background-color: #cb5b9f;
  margin-bottom: 46px;
  border-radius: 50%;
}

.button-confirm-container {
  display: flex;
  justify-content: space-around;
}

.button-confirm {
  width: 40%;
  background-color: green;
  border: none;
  color: white;
  padding: 2% 2%;
  margin: auto 0;
  margin-top: 30%;
}

.button-Cancle {
  width: 40%;
  background-color: red;
  border: none;
  color: white;
  padding: 2% 2%;
  margin: auto 0;
  margin-top: 30%;
}

.container-log-confirm>img {
  width: 70%;
  margin-left: 15%;
}

@media screen and (max-width: 1024px) and (max-height: 768px) {
  .container-log-confirm>img {
    width: 70%;
    margin-left: 15%;
  }

  .button-confirm-container {
    display: flex;
    justify-content: space-around;
  }

  .container-log-confirm>h1 {
    font-size: 18px;
    margin-left: 5%;
  }

  .button-confirm {
    width: 40%;
    background-color: green;
    border: none;
    color: white;
    padding: 2% 2%;
    margin: auto 0;
    margin-top: 30%;
  }

  .button-Cancle {
    width: 40%;
    background-color: red;
    border: none;
    color: white;
    padding: 2% 2%;
    margin: auto 0;
    margin-top: 30%;
  }
  .x-close {
    width: 18%;
    height: 15%;
    margin-left: 88%;
    margin-top: -30%;
    border-radius: 50%;
    background-color: #194792;
    color: white;
  }
}

@media screen and (max-width: 3840px) and (max-height: 2160px) {

  .navbar {
    height: 182px !important;
  }

  .container-log-confirm>img {
    width: 70%;
    margin-left: 15%;
  }

  .button-confirm-container {
    display: flex;
    justify-content: space-around;
  }

  .container-log-confirm>h1 {
    font-size: 18px;
    margin-left: 5%;
  }

  .button-confirm {
    width: 40%;
    background-color: green;
    border: none;
    color: white;
    padding: 2% 2%;
    margin: auto 0;
    margin-top: 10%;
  }

  .button-Cancle {
    width: 40%;
    background-color: red;
    border: none;
    color: white;
    padding: 2% 2%;
    margin: auto 0;
    margin-top: 10%;
  }
  .x-close {
    width: 18%;
    height: 15%;
    margin-left: 88%;
    margin-top: -30%;
    border-radius: 50%;
    background-color: #194792;
    color: white;
  }
  .logout-btn {
    margin-top: 70%;
  }
}