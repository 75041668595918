.main-container{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../Asset/missing_num_bg.png'); 
  background-size: cover;
}
.miss-backbutton {
  margin-left: 25px;
  margin-right: -25px;
  margin-top: 25px;
  background-color: #cb5b9f;
  margin-bottom: 46px;
  border-radius: 50%;
}
.series-container {
  display: grid;
  grid-template-rows: 1fr 1fr ;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 9.8rem;
  margin-top: -47.6rem;
  margin-left: 30rem;
  margin-right: 10rem;
}
.gif-container{
  height: 1080px;
  width: 1920px;
  margin-Left:-13rem;
  margin-Top: -25rem;
}
.heading{
  height: 4.5rem;
  width: 35rem;
  background-color:#FFCB23;
  border: solid 5px #FFCB23;
  box-shadow: 1px 1px  #b19026;
  border-radius: 10px;  
  margin-top: -14rem;
  margin-left: 30rem;
}
.head-para{
  font-size: 2rem;
  font-weight: 600;
  color: black;
  text-align: center;
}
.drop-zone {
  width: 200px;
  height: 208px;
  margin-left: 0rem;
  margin-top: -1rem;
  padding-top: 15px;
  color: black;
  background-image: url(../../Asset/images/train_box.png);
  display: flex;
  justify-content: center;
  font-size: 70px;
  font-family:  'Comic Sans MS', cursive;
}
.blank {
  color: #999;
}
.complete-series {
  display: flex;
  flex-wrap: wrap;
  margin-top: -7.5rem;
  margin-left: 15rem;
  padding: 5px;
  border: solid 3px #194792;
  border-radius: 20px;
  width: 80rem;
  height: 7rem;
  background-color: white;
}
.draggable-number {
  width: 50px;
  height: 50px;
  color: #194792;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -1rem;
  padding: 63px;
  font-size: 80px;
  font-family: 'Comic Sans MS', cursive;
  cursor: pointer;
  z-index: 1;
}
.miss-current-set{
  height: 6rem;
  width: 10rem;
  font-size: 50px;
  padding: 5px 12px;
  font-family: 'Comic Sans MS', cursive;
  background-color: #194792;
  color: white;
  border: solid 3px white;
  border-radius: 20px;
  margin-top: 1.6rem;
  margin-left: 2rem;
}
.miss-num-footer{
  height: 10rem;
  width: 100vw;
  background-color: #FFCB23;
  border: solid 5px #FFCB23;
  border-radius: 10px;  
  margin-top: -0.7rem;
}
.miss-pause-modal{
  width: 320px;
  height: 270px;
  background-color: aliceblue;
  overflow: hidden;
  margin-left: 5rem;
}
.miss-pause-title{
  color: #c99d0c;
  margin-top: -2rem;
  margin-left: 4rem;
}
.miss-pause-para{
  text-align: center;
  margin-top: 1rem;
  font-size: 20px;
  font-weight: bold;
  font-family: 'Comic Sans MS', cursive;
  color: #B22237 ;
}
.miss-play-btn{
  font-size: 1.5rem;
  height: 3rem;
  width: 3rem;
  border-radius: 50px;
  padding: 2px 5px ;
  margin-right: 6.5rem;
}
.miss-pause-btn{
  height: 2.5rem;
  width: 2.5rem;
  margin-top: 3rem;
  margin-left: 5rem;
  padding: 2px;
  cursor: pointer;
}
.miss-num-count{
  position: fixed;
  margin-top: -11.5rem;
  right: 5rem;
  height: 3rem;
  width: 12rem;
  background-color: #2d71b6;
  color: black;
  border-radius: 10px;
}
.miss-count-value{
  font-size: 27px;
  margin-top: 2px;
  margin-left: 20px;
  font-family: 'Comic Sans MS', cursive;
}
.miss-coin-image{
  width: 35px; 
  height: auto;
  margin-left: 50px;
  margin-top: -3px;
}
.miss-setting-modal{
  width: 300px;
  height: 270px;
  background-color: aliceblue;
  overflow: hidden;
  margin-top: 21rem;
  margin-left: 29rem;
}
.miss-set-btn{
  height: 2.5rem;
  width: 2.5rem;
  margin-top: -9rem;
  margin-left: 102rem;
  padding: 3px;
  cursor: pointer;
}
.miss-set-title{
  margin-top: -2rem;
  margin-left: 5rem;
}
.miss-volume-icon{
  size: 2rem;
  display: flex;
  margin-top: 1.5rem;
  margin-left: 2rem;
}
.miss-switch{
  margin-left: 1rem;
}
.miss-back-btn{
  background-color: #194792;
  height: 2.5rem;
  width: 5rem;
  padding: 3px;
  font-size: 20px;
}
.miss-home-btn{
  height: 2.5rem;
  width: 2.5rem;
  margin-top: -9rem;
  margin-left: -7rem;
  padding: 3px;
  cursor: pointer;
}
.miss-congrats-modal{
  animation: slideInFromLeft 1s ease-out;
}
.miss-complete-set{
  height: 618px;
  width: 1000px;
  margin-top: -11rem;
  margin-left: -38rem;
}
.miss-lose-modal{
  animation: slideInFromLeft 1s ease-out;
}
.miss-msg-lose {
  height: 618px;
  width: 1000px;
  margin-top: -11rem;
  margin-left: -38rem;
}
  @media (max-width: 1024px) and (max-height: 768px) {
    .heading{
      height: 3rem;
      width: 27rem;
      margin-top: -11rem;
      margin-left: 10rem;
    }
    .head-para{
      font-size: 1.7rem;
      margin-top: -0.6rem;
    }
    .miss-pause-btn{
      margin-top: -2rem;
      margin-left: 3rem;
    }
    .gif-container{
      margin-Left:-16.6rem;
      margin-Top: -20.5rem;
    }
    .series-container {
      grid-row-gap: 9.95rem;
      margin-top: -47.7rem;
      margin-left: 26.7rem;
      margin-right: 50rem;
    }
    .drop-zone {
      width: 130px;
      height: 208px;
      margin-left: -0.7rem;
      margin-top: -1rem;
      padding-top: 37px;
      padding-left: 43px;
      font-size: 45px;
    }
    .complete-series {
      width: 48rem;
      height: 4.5rem;
      margin-top: -5.1rem;
      margin-left: 10rem;
      border-radius: 20px;
    }
    .draggable-number {
      margin-top: -0.7rem;
      padding: 37px;
      font-size: 45px;
      z-index: 1;
    }
    .miss-num-count{
      margin-top: -7.5rem;
      right: 5rem;
      height: 2.8rem;
      width: 12rem;
    }
    .miss-count-value{
      font-size: 27px;
      margin-top: 1px;
      margin-left: 25px;
    }
    .miss-coin-image{
      margin-left: 60px;
    }
    .miss-current-set{
      height: 4rem;
      width: 10rem;
      font-size: 33px;
      padding: 2px 12px;
      margin-top: 0.8rem;
      margin-left: -0.2rem;
    }
    .miss-num-footer{
      height: 6.2rem;
      width: 100vw;
      margin-top: -0.5rem;
    }
    .miss-lose-modal{
      margin-top: -1rem;
      margin-left: -20.5rem;
      overflow: hidden;
    }
    .miss-msg-lose {
      height: 500px;
      width: 850px;
      margin-top: -5rem;
      margin-left: -3rem;
    } 
    .miss-congrats-modal{
      margin-top: -1rem;
      margin-left: -16rem;
      overflow: hidden;
    }
    .miss-msg-complete{
      height: 500px;
      width: 850px;
      margin-top: -5rem;
      margin-left: -6rem;
    }
    .miss-home-btn{
      margin-top: -6rem;
      margin-left: -5.2rem;
    }
    .miss-set-btn{
      margin-top: -6rem;
      margin-left: 61rem;
    }
    .miss-setting-modal{
      margin-top: 27rem;
      margin-left: 42rem;
    }
    .miss-pause-modal{
      margin-top: 17rem;
      margin-left: 22rem;
    }
  }

  @media (min-width:1025px) and (max-width:1280px) and (max-height:720px) {
    .heading{
      height: 3.2rem;
      width: 30rem;
      margin-top: -12.2rem;
      margin-left: 20rem;
    }
    .head-para{
      font-size: 1.8rem;
      margin-top: -0.6rem;
    }
    .miss-pause-btn{
      margin-top: -2rem;
      margin-left: 1rem;
    }
    .gif-container{
      margin-Left:-18.8rem;
      margin-Top: -23.5rem;
    }
    .series-container {
      grid-row-gap: 9.8rem;
      margin-top: -47.7rem;
      margin-left: 24.5rem;
    }
    .drop-zone {
      width: 190px;
      height: 208px;
      margin-left: -0.9rem;
      margin-top: -1rem;
      padding-top: 30px;
      font-size: 55px;
    }
    .complete-series {
      width: 57rem;
      height: 5rem;
      margin-top: -5.8rem;
      margin-left: 13rem;
      border-radius: 20px;
    }
    .draggable-number {
      margin-top: -0.7rem;
      padding: 44px;
      font-size: 55px;
      z-index: 1;
    }
    .miss-num-count{
      margin-top: -8.2rem;
      right: 5rem;
      height: 3rem;
      width: 12rem;
    }
    .miss-count-value{
      font-size: 27px;
      margin-top: 1px;
      margin-left: 25px;
    }
    .miss-coin-image{
      margin-left: 60px;
    }
    .miss-current-set{
      height: 4.5rem;
      width: 10.1rem;
      font-size: 35px;
      padding: 2px 12px;
      margin-top: 0.7rem;
      margin-left: 0.5rem;
    }
    .miss-num-footer{
      height: 6.4rem;
      width: 100vw;
      margin-top: -0.4rem;
    }
    .miss-lose-modal{
      margin-top: -1rem;
      margin-left: -20.5rem;
      overflow: hidden;
    }
    .miss-msg-lose {
      height: 500px;
      width: 850px;
      margin-top: -8rem;
      margin-left: -7rem;
    } 
    .miss-congrats-modal{
      margin-top: -1rem;
      margin-left: -22.5rem;
      overflow: hidden;
    }
    .miss-msg-complete{
      height: 500px;
      width: 850px;
      margin-top: -8rem;
      margin-left: -4rem;
    }
    .miss-home-btn{
      margin-top: -7rem;
      margin-left: -7rem;
    }
    .miss-set-btn{
      margin-top: -7rem;
      margin-left: 76rem;
    }
    .miss-setting-modal{
      margin-top: 24rem;
      margin-left: 48rem;
    }
    .miss-pause-modal{
      margin-top: 17rem;
      margin-left: 29rem;
    }
  }

  @media (min-width:1366px) and (min-height:768px){
    .heading{
      height: 3.2rem;
      width: 30rem;
      margin-top: -11.2rem;
      margin-left: 20rem;
    }
    .head-para{
      font-size: 1.8rem;
      margin-top: -0.6rem;
    }
    .miss-pause-btn{
      margin-top: -2rem;
      margin-left: 1rem;
    }
    .gif-container{
      margin-Left:-17.8rem;
      margin-Top: -22rem;
    }
    .series-container {
      grid-row-gap: 9.9rem;
      margin-top: -47.7rem;
      margin-left: 25.5rem;
    }
    .drop-zone {
      margin-left: -0.9rem;
      margin-top: -1rem;
      padding-top: 25px;
      font-size: 60px;
    }
    .complete-series {
      width: 60rem;
      height: 6rem;
      margin-top: -6.4rem;
      margin-left: 13rem;
      padding: 8px;
    }
    .draggable-number {
      margin-top: -0.7rem;
      padding: 46px;
      font-size: 60px;
      z-index: 1;
    }
    .miss-num-count{
      margin-top: -7.5rem;
      right: 5rem;
      height: 3rem;
      width: 12rem;
    }
    .miss-count-value{
      font-size: 27px;
      margin-top: 1px;
      margin-left: 25px;
    }
    .miss-coin-image{
      margin-left: 60px;
    }
    .miss-current-set{
      height: 4.7rem;
      width: 10.6rem;
      font-size: 37px;
      padding: 5px 12px;
      margin-top: 1.2rem;
      margin-left: 0.5rem;
    }
    .miss-num-footer{
      height: 7.5rem;
      width: 100vw;
      margin-top: -0.2rem;
    }
    .miss-lose-modal{
      margin-top: -1rem;
      margin-left: -20.5rem;
      overflow: hidden;
    }
    .miss-msg-lose {
      height: 500px;
      width: 850px;
      margin-top: -8rem;
      margin-left: -9rem;
    } 
    .miss-congrats-modal{
      margin-top: -1rem;
      margin-left: -22.5rem;
      overflow: hidden;
    }
    .miss-msg-complete{
      height: 500px;
      width: 850px;
      margin-top: -8rem;
      margin-left: -7rem;
    }
    .miss-home-btn{
      margin-top: -8rem;
      margin-left: -7rem;
    }
    .miss-set-btn{
      margin-top: -8rem;
      margin-left: 80rem;
    }
    .miss-setting-modal{
      margin-top: 25rem;
      margin-left: 62rem;
    }
    .miss-pause-modal{
      margin-top: 15rem;
      margin-left: 32rem;
    }
  }

  @media (min-width:1367px) and (max-width:1920px) and (max-height:1080px) and (display-mode:fullscreen){
    .heading{
      height: 4rem;
      width: 35rem;
      margin-top: -11.2rem;
      margin-left: 30rem;
    }
    .head-para{
      font-size: 2rem;
      margin-top: -0.5rem;
    }
    .miss-pause-btn{
      margin-top: 1rem;
      margin-left: 6rem;
    }
    .gif-container{
      margin-Left: -0.2rem;
      margin-Top: -10.9rem;
    }
    .series-container {
      grid-row-gap: 9.8rem;
      margin-top: -47.6rem;
      margin-left: 43.5rem;
    }
    .drop-zone {
      margin-left: -0.9rem;
      margin-top: -1rem;
      padding-top: 20px;
      padding-left: -10px;
      font-size: 65px;
    }
    .complete-series {
      width: 70rem;
      height: 7.5rem;
      margin-top: -7.8rem;
      margin-left: 27rem;
      padding: 12px;
    }
    .draggable-number {
      margin-top: -0.7rem;
      padding: 54px;
      font-size: 65px;
      z-index: 1;
    }
    .miss-num-count{
      margin-top: -8.5rem;
      right: 7rem;
      height: 3.5rem;
      width: 12rem;
    }
    .miss-count-value{
      font-size: 30px;
      margin-top: 3px;
      margin-left: 25px;
    }
    .miss-coin-image{
      margin-left: 60px;
    }
    .miss-current-set{
      height: 6rem;
      width: 12rem;
      font-size: 42px;
      padding: 10px 12px;
      margin-top: 1.8rem;
      margin-left: 5rem;
    }
    .miss-num-footer{
      height: 10rem;
      width: 100vw;
      margin-top: 4rem;
    }
    .miss-lose-modal{
      margin-top: -1rem;
      margin-left: 0.7rem;
      overflow: hidden;
    }
    .miss-msg-lose {
      height: 718px;
      width: 1200px;
      margin-top: -12rem;
      margin-left: -50rem;
    } 
    .miss-congrats-modal{
      margin-top: -1rem;
      margin-left: 0.2rem;
      overflow: hidden;
    }
    .miss-msg-complete{
      height: 718px;
      width: 1200px;
      margin-top: -12rem;
      margin-left: -50rem;
    }
    .miss-home-btn{
      margin-top: -10rem;
      margin-left: -7rem;
    }
    .miss-set-btn{
      margin-top: -10rem;
      margin-left: 110rem;
    }
    .miss-setting-modal{
      margin-top: 43rem;
      margin-left: 90rem;
    }
    .miss-pause-modal{
      margin-top: 25rem;
      margin-left: 50rem;
    }
    .miss-pause-title{
      font-size: 25px;
      margin-top: -2rem;
      margin-left: 4rem;
    }
    .miss-pause-para{
      text-align: center;
      margin-top: 0.5rem;
      font-size: 23px;
    }
    .miss-play-btn{
      padding: 2px 5px;
      margin-right: 6.5rem;
    }
  }
