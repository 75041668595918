  .thumb-container{
    background-image: url(../../Asset/bg3.jpg);
    text-align: center;
    height: 100vh;
    width: 100%;
    overflow:scroll; 
}
.thumb-con-card{ 
    align-content: center;
    display: flex;
    flex-wrap:wrap;
    flex-direction: row;  
    margin-top: 1rem;
}
.thumb_img {
    width:  auto !important;
    height: auto !important;
    margin-left: 3rem;
    margin-top: 2rem;
    cursor: url(/public/pointer.png), auto;
  }
 @media(max-width: 1024px) and (max-height: 768px)   {
      .thumb-container{
        background-image: url(../../Asset/bg3.jpg);
        text-align: center;
        height: 100vh;
        width: 100%;
        top: 0;
        overflow:scroll; 
        overflow-x: hidden;
    }
    .thumb-con-card{ 
        align-content: center;
        display: flex;
        flex-wrap:wrap;
        flex-direction: row; 
        margin-top: 1rem; 
    }
    .thumb_img {
        width:  auto !important;
        height: auto !important;
        margin-left: 2rem;
        margin-top: 3rem;
        cursor: url(/public/pointer.png), auto;
      }
}
@media (min-width:1025px) and (max-width:1280px) and (max-height:720px) {
  .thumb-container{
    background-image: url(../../Asset/bg3.jpg);
    text-align: center;
    height: 100vh;
    width: 100%;
    top: 0;
    overflow:scroll; 
    overflow-x: hidden;
}
.thumb-con-card{ 
  align-content: center;
  display: flex;
  flex-wrap:wrap;
  flex-direction: row; 
  margin-top: 1rem; 
}
.thumb_img {
  width:  auto !important;
  height: auto !important;
  margin-left: 2rem;
  margin-top: 3rem;
  cursor: url(/public/pointer.png), auto;
}
}

@media(min-width:1366px) and (min-height:768px) {
  .thumb-container{
    background-image: url(../../Asset/bg3.jpg);
    text-align: center;
    height: 100vh;
    width: 100%;
    top: 0;
    overflow:scroll; 
    overflow-x: hidden;
}
.thumb-con-card{ 
  align-content: center;
  display: flex;
  flex-wrap:wrap;
  flex-direction: row;  
  margin-top: 1rem;
}
.thumb_img {
  width:  auto !important;
  height: auto !important;
  margin-left: 3rem;
  margin-top: 2rem;
  cursor: url(/public/pointer.png), auto;
}
}

@media (min-width:1367px) and (max-width:1920px) and (max-height:1080px) {
  .thumb-container{
    background-image: url(../../Asset/bg3.jpg);
    text-align: center;
    height: 100vh;
    width: 100%;
    top: 0;
    overflow:scroll; 
    overflow-x: hidden;
}
.thumb-con-card{ 
  align-content: center;
  display: flex;
  flex-wrap:wrap;
  flex-direction: row;  
  margin-top: 3rem;
  margin-left: 2rem;
}
.thumb_img {
  width:  auto !important;
  height: auto !important;
  margin-left: 5rem;
  margin-top: 2rem;
  cursor: url(/public/pointer.png), auto;
}
}
