.color-main-div{
  display: flex;
}
.color-picker{
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.input-part{
  height: 2rem;
  width: 10rem;
  margin-left: 28rem;
  margin-top: 0.2rem;
  padding: 2%;
  box-shadow: none !important;
}
.pencil-colors{
  display: flex;
  height: 12rem;
}
.pencil-color{
    height: 14 !important;
    margin-top: 2rem;
  }
.horizontal-color-picker {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
.color-picker-container .color-picker .circle {
  border: 10px solid transparent; /* Set the border width */
  background-clip: padding-box;
}
.color-picker-container .color-picker .circle.active {
  border: 10px solid #57df3c; /* Set the border color to the selected color */
}
.pencil-width-indicator{
  margin-left: 28rem;
  font-size: 18px;
  align-items: center;
  border-radius: 10px;
  background-color: #FFCB23;
  padding: 5px 5px 5px 10px;
  margin-top: -24px;
}
@media (max-width: 1024px) and (max-height: 768px)   {
 .input-part{
    margin-left: 27rem;
  }
  .pencil-width-indicator{
    margin-left: 27rem;
    font-size: 22px;
    margin-top: -3rem;
    align-content: center;
    align-items: center;
  }
}
@media(min-width:1366px) and (min-height:768px) {
  .pencil-width-indicator{
    margin-top: -2rem;
    padding: 5px 5px 5px 20px;
  }
}
@media (min-width:1367px) and (max-width:1920px) and (max-height:1080px) {
  .input-part{
    margin-left: 30rem;
  }
  .pencil-width-indicator{
    margin-left: 30rem;
    font-size: 22px;
    margin-top: -9rem;
  }
}
 

  