.App {
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  width: 100%;
  height: 100%;
  background-image: url('../../Asset/game_bg_3.png'); 
  background-size: cover;
}
.matching-game{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  min-height: 100vh;
  position: relative;
}
.match-para1 {
  height: 4rem;
  width: 30rem;
  font-size: 25px;
  border: solid 5px #FFCB23;
  box-shadow: 1px 1px  #b19026;
  background-color:#FFCB23;
  margin-top: 1rem;
  color: black;
  font-family: 'Comic Sans MS',cursive;
  border-radius: 10px;
  padding: 10px 20px;
  display: inline-block; 
  justify-content: center;
}
.matching-pause-modal1{
  width: 320px;
  height: 270px;
  background-color: aliceblue;
  overflow: hidden;
 margin-left: 5rem;
}
.pause-btn1{
  height: 2.5rem;
  width: 2.5rem;
  margin-top: 5rem;
  margin-left: 5rem;
  padding: 2px;
  cursor: pointer;
}
.pause-title1{
  color: #c99d0c;
  margin-top: -2rem;
  margin-left: 4rem;
}
.pause-para1{
  text-align: center;
  margin-top: 1rem;
  font-size: 20px;
  font-weight: bold;
  font-family: 'Comic Sans MS', cursive;
  color: #B22237 ;
}
.play-btn1{
  font-size: 1.5rem;
  height: 3rem;
  width: 3rem;
  border-radius: 50px;
  padding: 2px 5px ;
  margin-right: 6.5rem;
}
.corr-ans-count{
  position: fixed;
  margin-top: 1.5rem;
  right: 5rem;
  height: 3rem;
  width: 12rem;
  background-color: #2d71b6;
  color: black;
  border-radius: 10px;
}
.count{
  font-size: 27px;
  margin-top: 2px;
  margin-left: 20px;
  font-family: 'Comic Sans MS', cursive;
}
.coin-image1 {
  width: 35px; 
  height: auto;
  margin-left: 50px;
  margin-top: -3px;
}
.set-btn1{
  height: 2.5rem;
  width: 2.5rem;
  margin-top: 30rem;
  margin-left: 10rem;
  padding: 3px;
  cursor: pointer;
}
.matching-setting-modal1{
  width: 300px;
  height: 270px;
  background-color: aliceblue;
  overflow: hidden;
  margin-top: 21rem;
  margin-left: 29rem;
}
.match-set-title{
  margin-top: -2rem;
  margin-left: 5rem;
}
.match-volume-icon{
  size: 2rem;
  display: flex;
  margin-top: 1.5rem;
  margin-left: 2rem;
}
.match-switch{
  margin-left: 1rem;
}
.match-back-btn{
  background-color: #194792;
  height: 2.5rem;
  width: 5rem;
  padding: 3px;
  font-size: 20px;
}
.home-btn1{
  height: 2.5rem;
  width: 2.5rem;
  margin-top:-4rem;
  margin-left: 2rem;
  padding: 3px;
  cursor: pointer;
}
.set {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 2%;
  margin-left:-32rem;
  margin-top: 8rem;
}
.canvas_dot{
  margin-top: -4rem;
  margin-left: -2rem;
}
.row11{
  display: flex;
  justify-content: center;
  margin-left: -15rem;
}
.row1{
  display: flex;
  justify-content: center;
  margin-left: -15rem;
}
.letter{
  width: 50px;
  height: 50px; 
  margin-left: 13rem;
  margin-top: -10rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.letter1 {
  width: 50px; 
  height: 50px; 
  margin-left: 13rem;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.msg {
  margin-top: -20rem;
  margin-left: 2rem;
}
.congrats-modal1{
  animation: slideInFromLeft 1s ease-out;
}
.complete-set1 {
  height: 618px;
  width: 1000px;
  margin-top: -11rem;
  margin-left: -38rem;
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 300px !important;
  height: 250px !important;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-clip: padding-box;
  border-radius: var(--bs-modal-border-radius);
}

  /* media query */
@media (max-width: 1024px) and (max-height: 768px) and (display-mode: fullscreen){
  .match-para1 {
    height: 4rem;
    width: 28rem;
    margin-top: 0.2rem;
    margin-left: 7rem;
    font-size: 25px;
  }
  .corr-ans-count{
    margin-top: 0.7rem;
    right: 1rem;
    height: 3rem;
    width: 12rem;
  }
  .pause-btn1{
    margin-top: 2rem;
    margin-left: 10rem;
    cursor: pointer;
  }
  .set {
    margin-left:-26rem;
    margin-top: 5rem;
  }
  .canvas_dot{
    margin-top: -7rem;
    margin-left: -4rem;
  }
  .row11{
    margin-left: -17rem;
  }
  .row1{
    margin-left: -17rem;
  }
  .letter{
    width: 30px;
    height: 30px; 
    margin-left: 13rem;
    margin-top: -11rem;
  }
  .letter1 {
    width: 30px; 
    height: 30px; 
    margin-left: 13rem;
    margin-top: 2rem;
  }
  .set-btn1{
    margin-top: 33rem;
    margin-left: 10rem;
  }
  .matching-setting-modal1{
    margin-top: 25rem;
    margin-left: 39rem;
  }
  .matching-pause-modal1{
    margin-top: 17rem;
    margin-left: 22rem;
  }
  .home-btn1{
    margin-top:-4.2rem;
    margin-left: 4rem;
  }
  .msg {
    margin-top: -23rem;
    margin-left: 2.7rem;
  }
  .congrats-modal1{
    margin-top: -1rem;
    margin-left: -16rem;
    overflow: hidden;
  }
  .complete-set1 {
    height: 500px;
    width: 850px;
    margin-top: -5rem;
    margin-left: -6rem;
  }
}

@media (min-width:1025px) and (max-width:1280px) and (max-height:720px) and (display-mode: fullscreen) {
  .match-para1 {
    height: 4rem;
    width: 28rem;
    margin-top: 0.1rem;
    margin-left: -5rem;
    font-size: 25px;
  }
  .corr-ans-count{
    margin-top: 0.7rem;
    right: 4rem;
    height: 3rem;
    width: 12rem;
  }
  .matching-pause-modal1{
    margin-top: 17rem;
    margin-left: 27rem;
  }
  .pause-btn1{
    margin-top: 4rem;
    margin-left: 5rem;
    cursor: pointer;
  }
  .set {
    margin-left:-28rem;
    margin-top: 5.5rem;
  }
  .canvas_dot{
    margin-top: -7rem;
    margin-left: -4rem;
  }
  .row11{
    margin-left: -17rem;
  }
  .row1{
    margin-left: -18rem;
  }
  .letter{
    width: 30px;
    height: 30px; 
    margin-left: 14rem;
    margin-top: -11rem;
  }
  .letter1 {
    width: 30px; 
    height: 30px; 
    margin-left: 14rem;
    margin-top: 2rem;
  }
  .set-btn1{
    margin-top: 31rem;
    margin-left: 10rem;
  }
  .matching-setting-modal1{
    margin-top: 23rem;
    margin-left: 55rem;
  }
  .home-btn1{
    margin-top:-4.2rem;
    margin-left: 2rem;
  }
  .msg {
    margin-top: -23rem;
    margin-left: 3rem;
  }
  .congrats-modal1{
    margin-top: -1rem;
    margin-left: 0.2rem;
  }
  .complete-set1 {
    height: 560px;
    width: 910px;
    margin-top: -9.9rem;
    margin-left: -34rem;
  }
}

@media(min-width:1366px) and (min-height:768px) and (display-mode: fullscreen){
  .match-para1 {
    height: 4rem;
    width: 28rem;
    margin-top: 0.8rem;
    margin-left: -5rem;
    font-size: 25px;
  }
  .corr-ans-count{
    margin-top: 1.2rem;
    right: 6rem;
    height: 3rem;
    width: 12rem;
  }
  .matching-pause-modal1{
    margin-top: 17rem;
    margin-left: 29rem;
  }
  .pause-btn1{
    margin-top: 4rem;
    margin-left: 5rem;
    cursor: pointer;
  }
  .set {
    margin-left:-30rem;
    margin-top: 5.5rem;
  }
  .canvas_dot{
    margin-top: -7rem;
    margin-left: -4rem;
  }
  .row11{
    margin-left: -17rem;
  }
  .row1{
    margin-left: -18rem;
  }
  .letter{
    width: 30px;
    height: 30px; 
    margin-left: 14rem;
    margin-top: -11rem;
  }
  .letter1 {
    width: 30px; 
    height: 30px; 
    margin-left: 14rem;
    margin-top: 2rem;
  }
  .set-btn1{
    margin-top: 31rem;
    margin-left: 10rem;
  }
  .matching-setting-modal1{
    margin-top: 23rem;
    margin-left: 59rem;
  }
  .home-btn1{
    margin-top:-4.2rem;
    margin-left: 2rem;
  }
  .msg {
    margin-top: -23rem;
    margin-left: 5rem;
  }
  .congrats-modal1{
    margin-top: -1rem;
    margin-left: 0.5rem;
  }
  .complete-set1 {
    height: 560px;
    width: 910px;
    margin-top: -9.9rem;
    margin-left: -34.5rem;
  }
}

@media (min-width:1367px) and (max-width:1920px) and (max-height:1080px) and (display-mode: fullscreen){
  .match-para1 {
    height: 5rem;
    width: 37rem;
    font-size: 35px;
    margin-top: 2rem;
    margin-left: -5rem;
  }
  .corr-ans-count{
    margin-top: 2.5rem;
    right: 10rem;
    height: 3rem;
    width: 13rem;
  }
  .count{
    font-size: 30px;
    margin-top: -1px;
  }
  .matching-pause-modal1{
    width: 350px;
    height: 300px;
    margin-top: 25rem;
    margin-left: 45rem;
  }
  .pause-btn1{
    margin-top: 9rem;
    margin-left: 8rem;
    cursor: pointer;
  }
  .play-btn1{
    font-size: 1.5rem;
    height: 3rem;
    width: 3rem;
    border-radius: 50px;
    padding: 2px 6px ;
    margin-right: 5.5rem;
  }
  .pause-title1{
    font-size: 25px;
    margin-left: 5rem;
  }
  .pause-para1{
    margin-top: 1rem;
    margin-left: 1.4rem;
    font-size: 25px;
  }
  .set {
    margin-left:-42rem;
    margin-top: 5.5rem;
  }
  .canvas_dot{
    margin-top: -7rem;
    margin-left: -4rem;
  }
  .row11{
    margin-left: -17rem;
  }
  .row1{
    margin-left: -18rem;
  }
  .letter{
    width: 30px;
    height: 30px; 
    margin-left: 14rem;
    margin-top: -11rem;
  }
  .letter1 {
    width: 30px; 
    height: 30px; 
    margin-left: 14rem;
    margin-top: 2rem;
  }
  .set-btn1{
    margin-top: 48rem;
    margin-left: 10rem;
  }
  .matching-setting-modal1{
    margin-top: 42rem;
    margin-left: 89rem;
  }
  .home-btn1{
    margin-top:-4.2rem;
    margin-left: 2rem;
  }
  .msg {
    margin-top: -32rem;
    margin-left: 5rem;
  }
  .complete-set1 {
    height: 618px;
    width: 1000px;
    margin-top: -11rem;
    margin-left: -37rem;
  }
}
