.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../Asset/game-bg_2.png'); 
  background-size: cover;
  z-index: -1;
}
.number-game {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  justify-content: center;
  min-height: 100vh;
  position: relative;
}
.game-content {
  text-align: center;
  width: 100%;
  height: 100%;
}
.question {
  margin-top: 0.5rem;
  margin-left: -4rem;
  color: black;
  font-family: 'Comic Sans MS',cursive;
}
.question-text {
  font-size: 25px;
  border: solid 5px #FFCB23;
  box-shadow: 1px 1px  #b19026;
  background-color:#FFCB23;
  border-radius: 10px;
  padding: 10px 20px;
  display: inline-block; 
}
.correct-answer-count {
  position: fixed;
  margin-top: 1.5rem;
  right: 5rem;
  height: 2.5rem;
  width: 12rem;
  background-color: #2d71b6;
  color: black;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.count-value {
  font-size: 27px;
  margin-top: -2px;
  margin-left: 12px;
  text-align: center;
  font-family: 'Comic Sans MS', cursive;
}
.coin-image {
  width: 35px; 
  height: auto;
  margin-left: 30px;
  margin-top: -5px;
}
.num-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(5, 1fr);
  justify-items: center;
  margin-top: 1rem;
  margin-left: -20rem;
  margin-right: -15rem;
}
.num-container .number {
  font-size: 40px;
  width: 140px;
  height: 140px;
  border-radius: 100px;
  font-family: 'Comic Sans MS', cursive;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: black;
  cursor: pointer;
  transition: transform 0.3s, opacity 0.3s;
}
.num-container .number:nth-child(1),
.num-container .number:nth-child(4),
.num-container .number:nth-child(7) {
  grid-column: 2; /* 1st row, 2nd column and 3rd row, 2nd column */
}
.num-container .number:nth-child(2),
.num-container .number:nth-child(5) {
  grid-column: 1; /* 2nd row, 1st column and  4th row, 1st column */
}
.num-container .number:nth-child(3),
.num-container .number:nth-child(6) {
  grid-column: 3; /* 2nd row, 3rd column and 4th row, 3rd column */
}
.num-container .number:hover {
  transform: scale(1.2);
  opacity: 1.5;
  box-shadow: 1px 2px 1px 1px #798cb9;
}
.message {
  font-size: 30px;
  margin-top: 2rem;
}
.set-btn{
  height: 2.5rem;
  width: 2.5rem;
  margin-top: 30rem;
  margin-left: 10rem;
  padding: 3px;
  cursor: pointer;
}
.home-btn{
  height: 2.5rem;
  width: 2.5rem;
  margin-top: -2rem;
  padding: 3px;
  cursor: pointer;
}
.identify-pause-modal{
  width: 320px;
  height: 270px;
  background-color: aliceblue;
  overflow: hidden;
}
.pause-btn{
  height: 2.5rem;
  width: 2.5rem;
  margin-top: 5rem;
  margin-left: 5rem;
  padding: 2px;
  cursor: pointer;
}
.pause-title{
  color: #c99d0c;
  margin-left: 4rem;
  margin-top: -2rem;
}
.pause-para{
  text-align: center;
  margin-top: 1rem;
  font-size: 20px;
  font-weight: bold;
  font-family: 'Comic Sans MS', cursive;
  color: #B22237 ;
}
.play-btn{
  background-color:  #194792;
  font-size: 1.5rem;
  height: 3rem;
  width: 3rem;
  border-radius: 50px;
  padding: 2px 5px ;
  margin-right: 6.2rem;
}
.identify-setting-modal{
  width: 320px;
  height: 270px;
  margin-top: 25rem;
  margin-left: 35rem;
  background-color: aliceblue;
  overflow: hidden;

}
.ide-set-title{
  margin-top: -2rem;
  margin-left: 5rem;
}
.volume-icon{
  size: 2rem;
  display: flex;
  margin-top: 1.5rem;
  margin-left: 2rem;
}
.switch{
  margin-left: 1rem;
}
.back-btn{
  background-color: #194792;
  height: 2.5rem;
  width: 5rem;
  padding: 3px;
  font-size: 20px;
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 300px !important;
  height: 250px !important;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-clip: padding-box;
  border-radius: var(--bs-modal-border-radius);
}
.correct-modal{
  animation: slideInFromLeft 1s ease-out;
}
.complete-set {
  height: 618px;
  width: 1000px;
  margin-top: -11rem;
  margin-left: -38rem;
}
.lose-modal{
  animation: slideInFromLeft 1s ease-out;
}
.msg-lose {
  height: 618px;
  width: 1000px;
  margin-top: -11rem;
  margin-left: -38rem;
}
  /* Media Query for Responsiveness */
@media (max-width: 1024px) and (display-mode: fullscreen){
.question {
    margin-top: 0.2rem;
    margin-left: -3rem;
}
.correct-answer-count {
    position: fixed;
    margin-top: 1rem;
    right: 3rem;
}
.num-container {
    margin-top: 0.7rem;
    margin-left: -17rem;
    margin-right: -15rem;
}
.num-container .number {
    font-size: 40px;
    width: 130px;
    height: 130px;
    padding-left: 13px;
    padding-top: 13px;
}
.identify-pause-modal{
  margin-top: 17rem;
  margin-left: 22rem;
}
.pause-btn{
    margin-top: 5rem;
    margin-left: 3rem;
    cursor: pointer;
}
.set-btn{
    margin-top: 33rem;
    margin-left: 10rem;
    cursor: pointer;
}
.identify-setting-modal{
    margin-top: 25rem;
    margin-left: 39rem;
}
.home-btn{
    height: 2.5rem;
    width: 2.5rem;
    margin-top: -2rem;
    margin-left: 2rem;
    cursor: pointer;
}
.lose-modal{
  margin-top: -1rem;
  margin-left: -20.5rem;
  overflow: hidden;
}
.msg-lose {
  height: 500px;
  width: 850px;
  margin-top: -5rem;
  margin-left: -3rem;
} 
.correct-modal{
  margin-top: -1rem;
  margin-left: -16rem;
  overflow: hidden;
}
.complete-set {
  height: 500px;
  width: 850px;
  margin-top: -5rem;
  margin-left: -6rem;
}
}

 @media (min-width: 1025px) and (max-width: 1280px) and (display-mode: fullscreen){
  .question {
    margin-top: 0.2rem;
    margin-left: -3rem;
  }
  .correct-answer-count {
    position: fixed;
    margin-top: 1rem;
    right: 5rem;
  }
  .num-container {
    margin-top: 0.3rem;
    margin-left: -19rem;
    margin-right: -15rem;
  }
  .num-container .number {
    font-size: 40px;
    width: 130px;
    height: 130px;
    padding-left: 13px;
    padding-top: 13px;
  }
  .identify-pause-modal{
    margin-top: 15rem;
    margin-left: 27rem;
  }
  .pause-btn{
    margin-top: 5rem;
    margin-left: 5rem;
    cursor: pointer;
  }
  .set-btn{
    margin-top: 29rem;
    margin-left: 10rem;
    cursor: pointer;
  }
  .identify-setting-modal{
    margin-top: 21rem;
    margin-left: 53rem;
  }
  .home-btn{
    height: 2.5rem;
    width: 2.5rem;
    margin-top: -2rem;
    margin-left: 2rem;
    cursor: pointer;
  }
 .pause-modal{
   margin-left: 5rem;
 }
.lose-modal{
  margin-top: -1rem;
  margin-left: -20.5rem;
  overflow: hidden;
}
.msg-lose {
  height: 600px;
  width: 960px;
  margin-top: -8rem;
  margin-left: -7rem;
} 
.correct-modal{
  margin-top: -1rem;
      margin-left: -22.5rem;
      overflow: hidden;
}
.complete-set {
  height: 500px;
  width: 850px;
  margin-top: -8rem;
  margin-left: -4rem;
}
}

 @media (min-width: 1281px) and (max-width: 1366px) and (display-mode: fullscreen) {
  .question {
    margin-top: 0.2rem;
    margin-left: -5rem;
  }
  .correct-answer-count {
    position: fixed;
    margin-top: 1rem;
    right: 7rem;
  }
  .num-container {
    margin-top: 0.5rem;
    margin-left: -22rem;
    margin-right: -17rem;
  }
  .num-container .number {
    font-size: 40px;
    width: 140px;
    height: 140px;
    padding-left: 5px;
    padding-top: 2px;
  }
  .identify-pause-modal{
    margin-top: 17rem;
    margin-left: 30rem;
  }
  .pause-btn{
    margin-top: 5rem;
    margin-left: 7rem;
    cursor: pointer;
  }
  .set-btn{
    margin-top: 31rem;
    margin-left: 10rem;
    cursor: pointer;
  }
  .identify-setting-modal{
    margin-top: 23rem;
    margin-left: 57rem;
  }
  .home-btn{
    height: 2.5rem;
    width: 2.5rem;
    margin-top: -2rem;
    margin-left: 2rem;
    cursor: pointer;
  }
 .pause-modal{
   margin-left: 4rem;
 }
 .lose-modal{
  margin-top: -1rem;
  margin-left: -20.5rem;
  overflow: hidden;
}
.msg-lose {
  height: 600px;
  width: 960px;
  margin-top: -8rem;
  margin-left: -9rem;
} 
.correct-modal{
  margin-top: -1rem;
  margin-left: -22.5rem;
  overflow: hidden;
}
.complete-set {
  height: 560px;
  width: 910px;
  margin-top: -8rem;
  margin-left: -7rem;
}
}

@media (min-width:1367px) and (max-width:1920px) and (display-mode: fullscreen) {
  .question {
    margin-top: 2rem;
    margin-left: -5rem;
  }
  .question-text{
    font-size: 30px;
  }
  .correct-answer-count {
    position: fixed;
    margin-top: 3rem;
    right: 11rem;
  }
  .num-container {
    row-gap: 20px;
    margin-top: 4.5rem;
    margin-left: -25rem;
    margin-right: -17rem;
  }
  .num-container .number {
    font-size: 40px;
    width: 140px;
    height: 140px;
    padding-left: 5px;
    padding-top: 2px;
  }
  .pause-btn{
    margin-top: 8rem;
    margin-left: 7rem;
    cursor: pointer;
  }
  .set-btn{
    margin-top: 45rem;
    margin-left: 12rem;
    cursor: pointer;
  }
  .identify-setting-modal{
    margin-top: 39rem;
    margin-left: 88rem;
  }
  .set-title{
    font-size: 25px;
    margin-left: 7rem;
  }
  .home-btn{
    height: 2.5rem;
    width: 2.5rem;
    margin-top: -2rem;
    margin-left: 4rem;
    cursor: pointer;
  }
  .modal-content {
    width: 350px !important;
    height: 270px !important;
  }
 .identify-pause-modal{
    width: 350px;
    height: 300px;
    margin-top: 25rem;
    margin-left: 45rem;
 }
 .pause-para{
  margin-top: 1.3rem;
  margin-left: 1.4rem;
  font-size: 25px;
}
.pause-title{
  font-size: 25px;
  margin-left: 5rem;
}
.play-btn{
  margin-right: 5.5rem;
}
.lose-modal{
  margin-top: -1rem;
  margin-left: 0.7rem;
  overflow: hidden;
}
.msg-lose {
  height: 718px;
  width: 1200px;
  margin-top: -12rem;
  margin-left: -50rem;
} 
.correct-modal{
  margin-top: -1rem;
  margin-left: 0.2rem;
  overflow: hidden;
}
.complete-set {
  height: 718px;
  width: 1200px;
  margin-top: -12rem;
  margin-left: -50rem;
}
}

