.right-side-part2{
  margin-bottom: 0%;
  height: 190vh;
  background-image: url(../../Asset/bg3.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%!important;
}
.form-control{
  width: 25rem;
  height: 3rem;
  font-size: 22px;
  padding: 17px;
  margin-top: 5rem;
  margin-left: 25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.form-select{
  width: 15rem;
  height: 2.5rem;
  font-size: 20px;
  padding: 5px;
  margin-left: 25rem;
  margin-top: 0.2rem;
}
.submitt-button{
  height: 3rem!important;
  width: 6rem!important;
  font-size: 20px;
  border-radius: 5px;
  margin-left: 25rem!important;
  margin-top: 5rem!important;
  background-color: #45803C;
  color: aliceblue;
  padding: 0%;
}
.submitt-button:disabled{
  cursor: not-allowed;
  background: #F89958;
}
.submitt-button:active.fonts {
  transform: translateY(-2px);
}

 /* media query for all screen size @tejaswini */
@media screen and (max-width: 1024px) {
  .form-control{
    padding: 17px;
    font-size: 20px;
    margin-top: 7rem;
    margin-left: 20rem;
  }
 .form-select{
  font-size: 19px;
  margin-left: 20rem;
  }
  .submitt-button{
    height: 2.5rem!important;
    width: 5rem!important;
    margin-left: 29rem!important;
    margin-top: 10rem!important;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
  .form-control{
    padding: 17px;
    margin-top: 8rem;
    margin-left: 28rem;
  }
 .form-select{
  margin-left: 28rem;
  }
  .submitt-button{
    height: 2.5rem!important;
    width: 5rem!important;
    margin-left: 37rem!important;
    margin-top: 10rem!important;
  }
}

@media screen and (min-width: 1281px) and (max-width: 1366px) {
  .form-control{
    width: 27rem;
    height: 3rem;
    padding: 17px;
    margin-top: 9rem;
    margin-left: 28rem;
  }
 .form-select{
    width: 17rem;
    height: 2.7rem;
    font-size: 20px;
    margin-left: 28rem;
  }
  .submitt-button{
    height: 3rem!important;
    width: 6rem!important;
    font-size: 22px;
    margin-left: 38rem!important;
    margin-top: 9rem!important;
  }
}

@media screen and (min-width: 1367px) and (max-width: 1920px) {
  .form-control{
    width: 30rem;
    height: 4rem;
    padding: 17px;
    font-size: 26px;
    margin-top: 15rem;
    margin-left: 42rem;
  }
 .form-select{
    width: 20rem;
    height: 3rem;
    font-size: 22px;
    margin-left: 42rem;
  }
  .submitt-button{
    height: 3.2rem!important;
    width: 7.5rem!important;
    font-size: 28px;
    margin-left: 52rem!important;
    margin-top: 12rem!important;
  }
}