.right-side-part1{
    margin-bottom: 0%;
    height: 190vh;
    background-image: url(../../Asset/bg3.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%!important;
}
  .content-update-button{
    width: 7rem;
    height: 3rem;
    font-size: 20px;
    margin-left: 38rem;
    margin-top: 5rem;
    background-color: rgb(10, 0, 100);
    padding: 0.4%;
  }
    /* media query for all screen size @tejaswini */
  @media screen and (max-width: 1024px) {
    .content-update-button{
      margin-left: 27rem;
      margin-top: 7rem;
      font-size: 22px;
    }
  }

  @media screen and (min-width: 1025px) and (max-width: 1280px) {
    .content-update-button{
      margin-left: 34rem;
      margin-top: 7rem;
      font-size: 23px;
    }
  }

  @media screen and (min-width: 1281px) and (max-width: 1366px) {
    .content-update-button{
      margin-left: 37rem;
      margin-top: 7rem;
      font-size: 24px;
    }
  }

  @media screen and (min-width: 1367px) and (max-width: 1920px) {
    .content-update-button{
      margin-left: 52rem;
      margin-top: 10rem;
      font-size: 27px;
      padding: 0.2%;
    }
  }