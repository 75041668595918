.Containerr {
  width: auto;
  height: 100vh;
  background-image: url(../../Asset/bg2.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  scroll-behavior: unset;
}
.Logincontainer {
  margin: 0 auto;
}
.justify-content-center {
  justify-content: center !important;
  margin-top: 0 auto;
  margin-top: 15%;
}
.landing-video {
  width: 45vw;
  justify-content: center;
  margin: 0 auto;
  background-color: #fcb116;
  border-radius: 10px;
}
.react-player_Home {
  margin: 0 auto;
  position: relative;
  border: solid 5px #fcb116;
  border-radius: 10px;
  width: auto;
  height: auto;
}
.form-Container {
  width: 30vw;
  height: 60vh;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.13);
  position: absolute;
  transform: translate(-50%, -50%);
  top: 62%;
  left: 78%;
  border-radius: 15px;
  backdrop-filter: blur(15px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
  padding-left: 2%;
  font-family: 'Poppins', sans-serif;
  color: #194792;
  letter-spacing: 0.5px;
  justify-content: space-between;
  outline: none;
  border: none;
}
.contact-form{
  margin-top: 2.5rem;
}
.form-message {
  margin-top: 18%;
}
/*  Added Login Btn effects */
input {
  background-color: whitesmoke;
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 10px #fddf2e;
  backdrop-filter: blur(10px);
  border-radius: 8px;
  margin-left: 3%;
  width: 87%;
  height: 48px;
  padding: 5%;
  border: none;
  margin-top: 2%;
  font-size: 14px;
  font-weight: 300;
  display: block;
}
button {
  width: 85%;
  height: 55%;
  padding: 6%;
  border: none;
  margin-top: 2%;
  margin-left: 2%;
  border-radius: 5px;
  margin-left: 4%;
  font-size: 15px;
  color: white;
}
button:hover {
  cursor: pointer;
}

.PushableLoginBtn {
  background: #fcb116;
  border-radius: 12px;
  border: none;
  padding: 0;
  margin-top: 10px;
  cursor: pointer;
  outline-offset: 4px;
}
.PushableLoginBtn:disabled{
  cursor: not-allowed;
  background: rgb(236, 218, 115);
}
.PushableLoginBtn:active .fonts {
  transform: translateY(-2px);
}
.fonts {
  display: block;
  padding: 12px 42px;
  border-radius: 12px;
  font-size: 1.25rem;
  background: transparent;
  color: white;
  transform: translateY(-6px);
}
.form-message {
  margin: 4%;
  font-size: 18px;
  font-weight: 500;
  line-height: 35px;
  text-align: center;
  font-family: Bodoni MT;
}

.logo {
  margin: 0 auto;
  top: 16%;
  position: absolute;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.logo img {
  width: 350px;
  height: 180px;
}

/* token expiored and days left part css */

.token_expired_modal{
  width: 1600px;
  height: 1500px;
  margin-top: -39rem;
  margin-left: -15rem;
  overflow: hidden;
}

.token_expired_close_icon{
  font-size: 2.5rem;
  margin-left: 47rem;
  margin-top: -74rem;
 }

.expire_soon_modal{
  width: 1600px;
  height: 1500px;
  margin-top: -39rem;
  margin-left: -15rem;
  overflow: hidden;
}
.expire_soon_close_icon{
  font-size: 2.5rem;
  margin-left: 47rem;
  margin-top: -74rem;
 }

.days_left_text{
  width: 9rem;
  font-size: 1.5rem;
  margin-left: 39.5rem;
  margin-top: -32.4rem;
  padding: inherit;
  position: absolute;
}

.days_left_num{
  height: 9rem;
  width: 9rem;
  background-color: #fafad2;
  border-radius: 100px;
  border: 5px solid #1E396A;
  font-size: 2.5rem;
  margin-left: 39rem;
  margin-top: -36rem;
  padding-left: 2.9rem;
  padding-top: 1rem;
  position: absolute;
}
.renewal_date{
  font-size: 1.2rem;
  font-weight: bold ;
  margin-left: 25rem;
  margin-top: -22rem;
  padding: inherit;
  position: absolute;
}
.user_name{
  width: 15rem;
  font-size: 1.7rem;
  font-weight: bold ;
  margin-left: 19rem;
  margin-top: -27rem;
  padding: inherit;
  position: absolute;
}

@media only screen and (max-width: 900px) {
  .containerr {
    height: 100vh;
    width: 100vw;
  }
  .landing-video {
    width: 95%;
    height: 50vh;
    margin-top: 5.5%;
    border-radius: 2px;
  }
  .form-Container {
    margin-top: 65%;
    width: 100%;
    height: 60vh;
    background-color: white;
  }

  input {
    background-color: #ecf3ff;
    border-radius: 5px;
    width: 90%;
    padding: 3%;
    border: none;
    margin-left: 1%;
  }
  button {
    width: 90%;
    border: none;
    margin-left: 5%;
    border-radius: 5px;
    background-color: #194792;
    font-size: 15px;
    color: white;
  }
  button:hover {
    cursor: pointer;
  }
  .copyright {
    color: white;
    margin-top: -2px;
    margin-bottom: 0px;
  }
}

@media only screen and (min-height: 720px) {
  .landing-video {
    margin-top: 17%;
  }
}

/*media for 1024 screen @ritesh*/
@media screen and (min-width: 1000px) and (max-width: 1024px) {
  .landing-video {
    margin-top: 65px;
    width: 50vw;
    height: 56.5vh;
  }

  .react-player_Home {
    margin: 0 auto;
    position: relative;
    border: solid 5px #fcb116;
    border-radius: 10px;
    width: 50vw;
    height:50vh;
  }
  
  .form-Container {
    width: 39vw;
    height: 57vh;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 61%;
    left: 76%;
  }
  .form-message > h1{
    margin: 4%;
    margin-left: 0.5rem;
  }
  .footer-copyright {
    margin-top: 10px;
    font-size: 24px;
    color: white;
    margin-left: 7%;
  }
  button.PushableLoginBtn {
    width: 85%;
    height: 55%;
    border: none;
    margin: 0px 18px;
  }
  .token_expired_modal{
    width: 1500px;
    height: 1300px;
    margin-top: -29rem;
    margin-left: -26rem;
    overflow: hidden;
  }
  .expire_soon_modal{
    width: 1500px;
    height: 1300px;
    margin-top: -29rem;
    margin-left: -26rem;
    overflow: hidden;
  }
}
/* 1280*720 */
@media screen and (min-width: 1025px) and (max-width: 1280px) {
  .landing-video {
    margin-top: 10px;
    width: 48vw;
    height: 57vh;
  }

  .react-player_Home {
    margin: 0 auto;
    position: relative;
    border: solid 5px #fcb116;
    border-radius: 10px;
    width: 30vw;
    height: auto;
  }
  .form-Container {
    width: 39vw;
    height: 57vh;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 62%;
    left: 76%;
  }
  .form-message > h1{
    margin: 4%;
    margin-left: -0.7rem;
  }
  .footer-copyright {
    margin-top: 0;
    font-size: 24px;
    color: white;
    margin-left: 7%;
  }
  button.PushableLoginBtn {
    width: 85%;
    height: 50%;
    border: none;
    margin: 0px 18px;
  }
  .token_expired_modal{
    width: 1550px;
    height: 1250px;
    margin-top: -28rem;
    margin-left: -20rem;
    overflow: hidden;
  }
  .expire_soon_modal{
    width: 1550px;
    height: 1250px;
    margin-top: -28rem;
    margin-left: -20rem;
    overflow: hidden;
  }
}

@media screen and (min-width: 1281px) and (max-width: 1366px) {
  .landing-video {
    margin-top: 25px;
    width: 48vw;
    height: 56vh;
  }
  .react-player_Home {
    margin: 0 auto;
    position: relative;
    border: solid 5px #fcb116;
    border-radius: 10px;
    width: 30vw;
    height: auto;
  }
  .form-Container {
    width: 39vw;
    height: 56vh;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 63%;
    left: 76%;
  }
  .form-message > h1{
    margin: 4%;
    margin-left: -0.7rem;
  }
  .footer-copyright {
    margin-top: 0;
    font-size: 24px;
    color: white;
    margin-left: 7%;
  }
  button.PushableLoginBtn {
    width: 85%;
    height: 55%;
    border: none;
    margin: 0px 20px;
  }
  .token_expired_modal{
    width: 1550px;
    height: 1350px;
    margin-top: -30rem;
    margin-left: -18rem;
    overflow: hidden;
  }
  .expire_soon_modal{
    width: 1550px;
    height: 1350px;
    margin-top: -30rem;
    margin-left: -18rem;
    overflow: hidden;
  }
}

@media screen and (min-width: 1367px) and (max-width: 1920px) {
  .landing-video {
    margin-top: 25px;
    width: 49vw;
    height: 56vh;
  }
  .react-player_Home {
    margin: 0 auto;
    position: relative;
    border: solid 5px #fcb116;
    border-radius: 10px;
    width: 30vw;
    height: auto;
  }
  .form-Container {
    width: 39vw;
    height: 56vh;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 62%;
    left: 76%;
  }
  .form-message > h1{
    margin: 4%;
    margin-left: -1.5rem;
  }
  .footer-copyright {
    margin-top: 0;
    font-size: 24px;
    color: white;
    margin-left: 7%;
  }
  input {
   margin-top: 3rem;
  }
  button.PushableLoginBtn {
    width: 87%;
    height: 55%;
    border: none;
    margin: 50px 25px;
  }
  .token_expired_modal{
    width: 1600px;
    height: 1500px;
    margin-top: -24rem;
    margin-left: -4rem;
    overflow: hidden;
  }
  .expire_soon_modal{
    width: 1600px;
    height: 1500px;
    margin-top: -24rem;
    margin-left: -4rem;
    overflow: hidden;
  }
}

@media (max-width: 3840px) and (max-height: 2160px) and (display-mode: fullscreen) {
  .form-Container {
    width: 39vw;
    height: 56.3vh;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 57%;
    left: 76.8%;
  }
  .footer-copyright {
    margin-top: 1%;
    font-size: 25px;
  }

  input {
    padding: 5.6%;
    margin-right: 10%;
  }
  .form-message > h1 {
    margin-right: 18px;
  }
  .fonts {
    font-size: 25px;
    text-align: center;
  }
  
}
